<template>
  <div :class="'main_area'">
    <Menu @openLoginModal="openLoginModal" />
    <div class="top_bar">
      <div class="logo_img" @click="switchHomePage"></div>
      <div class="aside_area">
        <button type="submit" class="search_btn" @click="searchOpen = true">
          <i class="search_icon"></i>
        </button>
        <button class="menu_icon" @click="sidebarOpen"/>
      </div>
      <div class="search_area_wrapper" v-if="searchOpen">
        <div class="search_area_block">
          <div class="search_area">
            <input type="text" class="search_text_m" placeholder="찾고싶은 퀴즈를 검색하세요." v-on:keyup.enter="searchQuiz" :value="searchText">
            <button type="submit" class="search_btn" @click="searchQuiz">
              <i class="search_icon"></i>
            </button>
            
          </div>
        
          <button class="cancel_btn" @click="searchOpen = false">
            취소
          </button>
        </div>
      </div>
      <div class="search_area_overlay" v-if="searchOpen"  @click="searchOpen = false">
      </div>
    </div>

    <div class="side_bar_overlay off" @click="sidebarClose"></div>
    <SideBarNew @openLoginModal="openLoginModal" @closeLoginModal="closeLoginModal" :loginModalOn="loginModalOn"></SideBarNew>

    <div class="main_content_area">
      <RouterView @openWriteModal="openWriteModal" @openModifyWriteModal=openModifyWriteModal @openLoginModal="openLoginModal"/>
      <FooterNew v-if=" this.$route.path.indexOf('quiz') < 0"/>
    </div>
  
  </div>

  <WriteModal ref="writeModal" :categoryId="categoryId" :writeModalOn="writeModalOn" @closeWriteModal=closeWriteModal :modifyQuizId="modifyQuizId" v-show="writeModalOn"/>
  <DailyQuizModal @closeDailyQuizModal=closeDailyQuizModal :dailyQuizId="dailyQuizId" :dailyQuizModalOn="dailyQuizModalOn" v-if="dailyQuizModalOn"></DailyQuizModal>
</template>

<script>

import Loader from "@/components/common/Loader";
import Menu from "@/components/layout/Menu";
import router from "@/utils/router";
import WriteModal from "@/components/modal/write/WriteModal.vue";
import DailyQuizModal from "@/components/modal/quiz/DailyQuizModal.vue";
import Footer from "@/components/layout/Footer.vue";
import "vue3-toastify/dist/index.css";
import axios from "axios";
import {toastFail, toastInfo} from "@/utils/toastUtils";
import apiClient from "@/plugins/apiClient";
import FooterNew from "@/components/layout/Footer_New.vue";
import {useRoute} from "vue-router";
import SideBarNew from "../main/sidebar/SideBarNew.vue";

export default {
  name: 'Main',
  components: {Footer, DailyQuizModal, WriteModal, SideBarNew, Loader, Menu, FooterNew},
  data: () => ({
    quickBtnOpen : false,
    writeModalOn: false,
    dailyQuizModalOn: false,
    dailyQuizId : null,
    dailyQuizTodayCheck: false,
    login : false,
    deleteRegMsg : false,
    deleteDailyMsg : false,
    resFlag: false,
    loginModalOn: false,
    modifyQuizId: 0,
    searchOpen: false,
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    },
    setDailyQuizId(){
      this.dailyQuizId = this.$store.state.dailyQuiz.dailyQuizId;
      return this.$store.state.dailyQuiz.dailyQuizId;
    },
    setDailyQuizTodayCheck(){
      this.dailyQuizTodayCheck = this.$store.state.dailyQuiz.dailyQuizTodayCheck;
      return this.$store.state.dailyQuiz.dailyQuizTodayCheck;
    },
  },
  watch:{
    isLoginCheck(){
      this.login = !!this.$store.state.auth.isLogin;
    },
    setDailyQuizId(){
      this.dailyQuizId = this.$store.state.dailyQuiz.dailyQuizId;
      this.resFlag = true;
    },
    setDailyQuizTodayCheck(){
      this.dailyQuizTodayCheck = this.$store.state.dailyQuiz.dailyQuizTodayCheck;
      this.resFlag = true;
    }
  },
  created() {
    if(this.$cookies.get("delete_reg_msg") !== null){
      this.deleteRegMsg = this.$cookies.get("delete_reg_msg");
    }

    if(this.$cookies.get("delete_daily_msg") !== null){
      this.deleteDailyMsg = this.$cookies.get("delete_daily_msg");
    }
  },
  methods: {
    sidebarOpen(){
      let sideBarAttr = $(".side_bar").css('display');

      if(sideBarAttr == 'block'){
        $(".side_bar").css('display', 'none');
        $(".side_bar_overlay").removeClass('on');
      }else{
        $(".side_bar").css('display', 'block');
        $(".side_bar_overlay").addClass('on');
      }
    },
    sidebarClose(){
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    },
    switchHomePage: function (){
      router.push('/');
    },
    subQuickBtnOpen: function(){
      if(!this.quickBtnOpen){
        this.quickBtnOpen = true;
        $(".floating_btn_area").addClass('open');
        $(".floating_btn_area").removeClass('close');
      }else{
        this.quickBtnOpen = false;
        $(".floating_btn_area").removeClass('open');
        $(".floating_btn_area").addClass('close');
      }
    },
    openWriteModal(categoryId){
      this.writeModalOn = true;
      if(categoryId != null){
        console.log(categoryId);
        this.categoryId = categoryId;
      }
    },
    openModifyWriteModal(quizId){
      this.writeModalOn = true;
      this.$refs.writeModal.modify(quizId);
    },
    closeWriteModal: function (){
      this.writeModalOn = false;
      this.categoryId = null;
    },
    openDailyQuizModal(dailyQuizId){
      if(dailyQuizId === 0){
        this.sidebarClose();
        router.push('/daily');

        return;
      }

      apiClient.get('/api/daily-quiz/check/' + dailyQuizId).then(data => {
        if(data.data.resData === false) {
          this.$store.dispatch('setDailyQuizTodayCheck', false);
          this.dailyQuizId = dailyQuizId;
          this.dailyQuizModalOn = true;
        }else{
          this.$store.dispatch('setDailyQuizTodayCheck', true);
          toastInfo('오늘 일일 퀴즈는 이미 수행하였습니다.');
          return false;
        }
      });
    },
    closeDailyQuizModal: function (){
      this.dailyQuizModalOn = false;
    },
    deleteRegMsgFn(){
      this.deleteRegMsg = true;
      this.$cookies.set("delete_reg_msg",true,60 * 60);
    },
    deleteDailyMsgFn(){
      this.deleteDailyMsg = true;
      this.$cookies.set("delete_daily_msg",true,60 * 60);
    },
    openLoginModal: function (){
      this.loginModalOn = true;
    },
    closeLoginModal: function (){
      this.loginModalOn = false;
    },
    searchQuiz(){
      let searchText = $(".search_text_m").val();

      if(searchText == ''){
        toastInfo('검색어를 입력해주세요.');
        return false;
      }
      this.searchOpen = false;

      router.push('/search?q=' + searchText);
    }
  }
}
</script>

<style>
  @import "@/assets/layout/Main.css";
  @import "@/resource/css/reset.css";
  @import "@/resource/css/default-style.css";
</style>