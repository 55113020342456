<template>
  <div class="container_myPage">  
    <section class="section">
      <div class="section_profile">
        <div class="profileImg_block">
          <div class="block _1">
            <img class="img"  :src="profileImgUrl"  @error="defaultImg"  @click="$router.push('/mypage');" alt="기본 프로필 이미지"/>
          </div>
          <div class="block _2">
            <span>{{userName}}</span>
          </div>
          <div class="block _3">
            <button class="btn edit" @click="openProfileEditModal">수정</button>
          </div>
        </div>
      </div>
      <p class="section_label">내 활동</p>
      <div class="section_cnt">
        <div class="cnt_block">
          <div class="cnt myQuiz" @click="$router.push('/manage')">
            <span class="content_cnt">{{ quizCnt }}</span>
            <span class="content_desc">📝 내 퀴즈</span>
          </div>
          <div class="line"></div>
          <div class="cnt answered" @click="$router.push('/answered')">
            <span class="content_cnt">{{ answeredCnt }}</span>
            <span class="content_desc">💬 답변 내역</span>
          </div>
          <div class="line"></div>
          <div class="cnt like" @click="$router.push('/liked')">
            <span class="content_cnt">{{ likedCnt }}</span>
            <span class="content_desc">👍 좋아요 <br class="next_line">표시한 퀴즈</span>
          </div>
          <div class="line"></div>
          <div class="cnt interested" @click="$router.push('/interested')">
            <span class="content_cnt">{{ interestedCnt }}</span>
            <span class="content_desc">📌 관심퀴즈</span>
          </div>
        </div>
      </div>
      <p class="section_label">관리</p>
      <div class="section_feature">
        <div class="feature_block daily"  @click="$router.push('/daily')">
          <p class="content daily">📖 일일 퀴즈 관리</p>
        </div>
      </div>
    </section>
  </div>
  <ProfileEditModal @closeProfileEditModal="closeProfileEditModal" :profileEditModalOn="profileEditModalOn" @reloadMyInfo="reloadMyInfo" v-if="profileEditModalOn"/>
</template>
<script>
import Footer_New from '@/components/layout/Footer_New.vue';
import ProfileEditModal from '@/components/modal/profile/ProfileEditModal.vue';
import apiClient from "@/plugins/apiClient";


export default{
  name: 'MyPage',
  components: {ProfileEditModal, Footer_New},
  data : () => ({
    profileEditModalOn : false,
    userName : '',
    profileImgUrl : '',
    quizCnt: 0,
    answeredCnt: 0,
    likedCnt: 0,
    interestedCnt: 0,
  }),
  mounted() {
    this.getUserInfo();
    this.getQuizCnt();
  },
  computed:{
  },
  watch:{
  },
  methods:{
    openProfileEditModal: function (){
      this.profileEditModalOn = true;
    },
    closeProfileEditModal: function (){
      this.profileEditModalOn = false;
    },
    getUserInfo(){
      apiClient.get('/api/user').then(data => {
        this.userName = data.data.resData.userName;
        this.profileImgUrl = data.data.resData.profileImgUrl;
      });
    },
    getQuizCnt(){
      apiClient.get('/api/my-page/count').then(data => {
        this.quizCnt = data.data.resData.quizCnt;
        this.answeredCnt = data.data.resData.answeredCnt;
        this.likedCnt = data.data.resData.likedCnt;
        this.interestedCnt = data.data.resData.interestedCnt;
      });
    },
    reloadMyInfo(){
      this.getUserInfo();
    },
    defaultImg(e){
      e.target.src = require('@/resource/img/person.png');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/common/animation.css";
@import "@/assets/main/mypage/MyPage.css";
</style>