import moment from "moment";

export default{
    dateToString(date){
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + (date.getDate())).slice(-2);

        return `${year}-${month}-${day}`;
    },
    dateToStringYYYYMM(date){
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + (date.getDate())).slice(-2);

        return `${year}-${month}`;
    },
    timeFormat (date) {
        moment.locale("en");

        return moment(date).fromNow();
    }
}