<template>
  <div class="container_home">
    <section class="section">
      <div class="section_block _1">
        <div class="content_block">
          <span class="content _1" data-sal="zoom-in" :data-sal-duration="500" data-sal-easing="ease-out-back">
            위즐큐는 용어 학습을 도와드립니다.
          </span>

          <span class="content _2" data-sal="slide-up" :data-sal-duration="500">단순 암기를 넘어, <br class="newline"/> 개념을 한곳에 모아 깊이 있는 학습을 경험해 보세요.</span>
          <button class="start_btn login" @click="openWriteModal" v-if="login" data-sal="slide-up"  :data-sal-duration="500">퀴즈 등록</button>
          <button class="start_btn not_login" @click="openLoginModal" v-else data-sal="slide-up"  :data-sal-duration="500">시작하기</button>

          <div class="content_block_border" data-sal="zoom-in" :data-sal-duration="300" data-sal-easing="easeInQuint"></div>
        </div>
      </div>
      
      
      <div class="section_block _2">
        <img class="img" src="@/resource/img/guide_quizmodal.png"  data-sal="slide-up" :data-sal-duration="500"/>
      </div>
    </section>
  </div>
</template>
<script>
import Loader from "@/components/common/Loader";
import RegisterModal from "@/components/modal/regist/RegisterModal";
import WriteModal from "@/components/modal/write/WriteModal";
import sal from "sal.js";
import Footer from "@/components/layout/Footer.vue";
import router from "@/utils/router";

export default{
  name: 'Home_New',
  components: {WriteModal, RegisterModal, Loader},
  data : () => ({
    login : false,
    registerModalOn : false,
    writeModalOn: false,
    scrollAnimationDuration: 500
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    }
  },
  watch:{
    isLoginCheck(){
      this.login = !!this.$store.state.auth.isLogin;
    }
  },
  mounted() {
    sal();
  },
  methods: {
    openLoginModal: function (){
      this.$emit('openLoginModal');
    },
    openWriteModal(){
      if(!this.login){
        this.$emit('openLoginModal');
        return;
      }
      this.$emit('openWriteModal');
    },
    moveDailyQuizManage(){
      if(!this.login){
        this.$emit('openLoginModal');
        return;
      }
      router.push('/daily');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/common/animation.scss";
@import "@/assets/main/home/HomeNew.scss";
</style>