<template>
  <div class="footer_area">
    <div class="footer_left">
      <div class="logo_area">
        <div class="logo_img"></div>
      </div>

      <a href="mailto:wizlqservice@gmail.com" class="info_label link">서비스 문의 : wizlqservice@gmail.com</a>

      <div class="privacy_area">
        <span @click="service" class="info_label link">이용약관</span>
        <span @click="privacy" class="info_label link bold">개인정보처리방침</span>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/utils/router";

export default {
  name: 'FooterNew',
  methods: {
    privacy: function (){
      router.push('/term/privacy');
    },
    service: function (){
      router.push('/term/service');
    },
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/layout/FooterNew.css";
</style>