<template>
  <div class="side_bar">
    <div class="nav_area">
      <div class="nav_sub_area">
        <div class="sub_area">
          <div class="manage_area">
            <p class="label_category">관리</p>
            <p class="content myPage" @click="switchMyPage">My Page</p>
          </div>
          <div class="category_area">
            <p class="label_category">카테고리</p>
            <template v-for="mainCategory in categoryArr">
              <div :class="'category depth1 id_' + mainCategory.categoryId" @click="openCategory(mainCategory.categoryId)">
                <p class="category_depth1_name">{{mainCategory.categoryName}}</p>
              </div>
              <template v-for="subCategory in mainCategory.subCategoryList">
                <div :class="'category depth2 idx_' + mainCategory.categoryId">
                  <div :class="'category_detail id_' + subCategory.subCategoryId " @click="loadCategoryProblemList(subCategory.subCategoryId)">
                    <p class="category_depth2_name">{{ subCategory.subCategoryName }}</p>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div :class="'profile_area' + (!login ? ' not_login' : '') " v-if="login">
      <div class="profile_img_area" @click="openProfileEditModal">
        <img :src="profileImgUrl" @error="defaultImg"/>
      </div>
      <div class="profile_info_area">
        <p class="profile_name" v-if="login">{{ userName }}</p>
      </div>
      <p class="logout_label" @click="logout" ></p>
    </div>
    <div class="login_area" v-else @click="openLoginModal">
      <div class="profile_img_area">
        <img class="default_img" src="@/resource/img/person.png" alt="기본 프로필 이미지"/>
      </div>
      <div class="login_required_area">
        <p>로그인이 필요합니다!</p>
      </div>
    </div>
  </div>
<!--  <LoginModal @closeLoginModal="closeLoginModal" @openRegisterModal=openRegisterModal v-if="loginModalOn"/>-->
  <LoginModalNew @closeLoginModal="closeLoginModal" @openRegisterModal=openRegisterModal v-if="loginModalOn"/>
</template>

<script>

import router from "../../../utils/router";
import DailyQuizModal from "@/components/modal/quiz/DailyQuizModal";
import RegisterModal from "@/components/modal/regist/RegisterModal";
import ProfileEditModal from "@/components/modal/profile/ProfileEditModal";
import {toastFail, toastSuccess} from "@/utils/toastUtils";
import LoginModalNew from "@/components/modal/login/LoginModalNew";
import apiClient from "@/plugins/apiClient";

export default {
  name: 'SideBarNew',
  props: ['loginModalOn'],
  components: {LoginModalNew, ProfileEditModal, RegisterModal, DailyQuizModal},
  data: () => ({
    tabOn : 'category',
    login : false,
    userName : '',
    profileImgUrl : '',
    dailyQuizId : 0,
    dailyQuizModalOn: false,
    registerModalOn : false,
    profileEditModalOn : false,
    categoryArr: []
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    }
  },
  watch:{
    isLoginCheck(){
      if(this.$store.state.auth.isLogin){
        this.login = true;
        this.getUserInfo();
      }else {
        $(".vc-switch-input").click();
        this.tabOn = 'category';
        this.login = false;
      }
    }
  },
  created() {
    if(this.login){
      this.getUserInfo();
    }
    this.getCategory();
  },
  methods: {
    openCategory: function (cateId){

      if($(".category.depth1.id_" + cateId).hasClass('open')){
        $(".category.depth1.id_" + cateId).removeClass('open')

        $(".category.depth2.idx_" + cateId).each(function(){
          $(this).removeClass('open');
        })
      }else{
        $(".category.depth1.id_" + cateId).addClass('open');

        $(".category.depth2.idx_" + cateId).each(function(){
          $(this).addClass('open');
        })
      }
    },
    getCategory: function (){
      apiClient.get('/api/public/categories').then(data => {
        this.categoryArr = data.data.resData;
      });
    },
    loadCategoryProblemList(selectCategoryId){
      $(".category_detail").removeClass('active');
      $(".category_detail.id_" + selectCategoryId).addClass('active');

      this.sidebarClose2();
      router.push('/quiz/' + selectCategoryId);
    },
    sidebarClose2(){
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    },
    defaultImg(e){
      e.target.src = require('@/resource/img/person.png');
    },
    clickTab(idx){
      this.isOn = idx;
    },
    getUserInfo(){
      apiClient.get('/api/user').then(data => {
        let dailyQuizId = data.data.resData.dailyQuizId;

        this.userName = data.data.resData.userName;
        this.profileImgUrl = data.data.resData.profileImgUrl;
        this.dailyQuizId = dailyQuizId;

        this.$store.dispatch('setDailyQuizId', dailyQuizId == null ? 0 : dailyQuizId);

        if(dailyQuizId !== null && dailyQuizId !== 0){
          apiClient.get(`/api/daily-quiz/check/` + dailyQuizId).then(data => {
            if(data.data.resData === true) {
              this.$store.dispatch('setDailyQuizTodayCheck', true);
            }else{
              this.$store.dispatch('setDailyQuizTodayCheck', false);
            }
          });
        }
      });
    },
    tabChange(){
      if(this.tabOn == '' || this.tabOn == 'manage'){
        this.tabOn = 'category';
      }else{
        this.tabOn = 'manage';
      }
    },
    switchHomePage: function (){
      router.push('/');
      $(".side_bar").css('display', 'none');
      $(".side_bar_overlay").removeClass('on');
    },
    sidebarClose(){
      $(".category_detail").removeClass('active');
      $(".side_bar_overlay").removeClass('on');
    },
    closeDailyQuizModal: function (){
      this.dailyQuizModalOn = false;
    },
    closeRegisterModal: function (){
      this.registerModalOn = false;
    },
    openRegisterModal: function (){
      this.registerModalOn = true;
    },
    closeProfileEditModal: function (){
      this.profileEditModalOn = false;
    },
    openProfileEditModal: function (){
      this.profileEditModalOn = true;
    },
    reloadMyInfo(){
      this.getUserInfo();
    },
    openLoginModal: function (){
      this.$emit('openLoginModal');
    },
    closeLoginModal: function (){
      this.$emit('closeLoginModal');
    },
    async logout() {
      this.$store.dispatch('logout');
      toastSuccess("로그아웃 되었습니다.");
    },
    switchMyPage(){
      if(!this.login){
        toastFail("로그인 후 이용 가능합니다.");
      } else{
        router.push('/mypage');
        this.sidebarClose2();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/main/sidebar/SideBarNew.css";
</style>