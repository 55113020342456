<template>
  <div class="menu_area">
    <div class="menu_bar_area">
      <div style="display: flex;">
          <div class="logo_area" >
            <div class="logo_img" @click="$router.push('/')"></div>
          </div>
          <div class="menus">
            <div class="menu category_area" @click="categoryItemSwitch">
              <p class="category_p">카테고리</p>
            </div>
            <div :class="'category_item_area' + categoryItemOpenFlag" v-click-outside="categoryItemClose">
              <div class="main_category_list">
                <template v-for="item in mainCategoryList">
                  <p :class="'item' + (activeCategoryId == item.categoryId ? ' active': '')"  @click="getSubCategory(item.categoryId)" @mouseover="getSubCategory(item.categoryId)">{{ item.categoryName }}</p>
                </template>
              </div>
              <div class="line"/>
              <div class="sub_category_list">
                <template v-for="item in subCategoryList">
                  <p class="item" @click="categoryMove(item.subCategoryId)">{{ item.subCategoryName }}</p>
                </template>
              </div>
            </div>
          </div>
      </div>
      <div class="aside_area">
        <div class="search_area pc">
          <input type="text" class="search_text" placeholder="찾고싶은 퀴즈를 검색하세요." v-on:keyup.enter="searchQuiz" :value="searchText">
          <button type="submit" class="search_btn" @click="searchQuiz">
            <i class="search_icon"></i>
          </button>
        </div>
        <div class="my" v-if="login" @mouseenter="openManageMenu = true" @mouseleave="openManageMenu = false">
          <div @click="$router.push('/mypage'); openManageMenu = false">
            <img class="img"  :src="profileImgUrl"  @error="defaultImg"  @click="$router.push('/mypage');" alt="기본 프로필 이미지"/>
          </div>
          <div class="manage_menu_wrapper" v-if="openManageMenu" v-click-outside="manageMenuOutSideClick">
            <div class="manage_menu_list">
              <p :class="'menu _1'" @click="$router.push('/mypage'); openManageMenu = false;">My Page</p>
              <p :class="'menu _2'"  @click="logout">로그아웃</p>
            </div>
          </div>
        </div>
        <div v-else>
          <button class="btn login" @click="openLoginModal">시작하기</button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

import apiClient from "@/plugins/apiClient";
import vClickOutside from "click-outside-vue3";
import router from "@/utils/router";
import {toastInfo, toastSuccess} from "@/utils/toastUtils";

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'Menu',
  data:() => ({
    categoryItemOpenFlag: '',
    mainCategoryList: [],
    subCategoryList: [],
    activeCategoryId: 0,
    login : false,
    openManageMenu: false,
    profileImgUrl : '',
  }),
  computed:{
    isLoginCheck(){
        this.login = this.$store.state.auth.isLogin;
        return this.$store.state.auth.isLogin;
    },
  }, 
  watch:{
    isLoginCheck(){
      if(this.$store.state.auth.isLogin){
        this.login = true;
        this.getUserInfo();
      }else{
        this.login = false;
      }
    }
  },
  created() {
    this.getCategory();
  },
  methods:{
    defaultImg(e){
      e.target.src = require('@/resource/img/person.png');
    },
    categoryItemSwitch(){
      if(this.categoryItemOpenFlag == ' _open'){
        this.categoryItemOpenFlag = ' _close'; 
      }
      else{
        this.categoryItemOpenFlag = ' _open'; 
      }
    }, 
    categoryItemClose(event){
      if(event.target.parentNode == null || event.target.parentNode.className == null) return;
      console.log(event.target.parentNode.className);
      if(event.target.parentNode.className.indexOf("menu") < 0){
        if(this.categoryItemOpenFlag == ' _open'){
          this.categoryItemOpenFlag = ' _close'; 
        }
      }
    },
    getCategory: function (){
      apiClient.get('/api/public/categories').then(data => {
        const categoryArr = data.data.resData;
        this.categoryResult = categoryArr;
        this.mainCategoryList = categoryArr.map(({ categoryId, categoryName }) => ({ categoryId, categoryName }));
      });
    },
    getSubCategory(categoryId){
      this.activeCategoryId = categoryId;
      const category = this.categoryResult.find(cat => cat.categoryId === categoryId);
      this.subCategoryList = category ? category.subCategoryList: [];
    },
    categoryMove(subCategoryId){
      router.push('/quiz/' + subCategoryId);
      this.categoryItemOpenFlag = ' _close'; 
    },    
    manageMenuOutSideClick(event){
      this.openManageMenu = false;
    },
    openLoginModal: function (){
      this.$emit('openLoginModal');
    },
    async logout() {
      toastSuccess("로그아웃 되었습니다.");
      this.$store.dispatch('logout');
      router.push('/');
      this.openManageMenu = false;
    },
    getUserInfo(){
      console.log(this.login);
      if(this.login){
        apiClient.get('/api/user').then(data => {
          this.profileImgUrl = data.data.resData.profileImgUrl;
        });
      }
    },
    searchQuiz(){
      let searchText = $(".search_text").val();

      if(searchText == ''){
        toastInfo('검색어를 입력해주세요.');
        return false;
      }

      router.push('/search?q=' + searchText);
    }
  }
}
</script>


<style>
  @import "@/assets/common/animation.css";
@import "@/assets/layout/Menu.css";
</style>