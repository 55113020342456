
import Home from "@/components/main/home/Home";
import {createMemoryHistory, createRouter, createWebHistory} from "vue-router";
import MyQuizManage from "@/components/main/manage/MyQuizManage";
import DailyQuizManage from "@/components/main/manage/DailyQuizManage";
import Content from "@/components/main/content/Content";
import store from "@/store";
import Privacy from "@/components/main/term/Privacy";
import Service from "@/components/main/term/Service";
import PrivacyAgree from "@/components/main/term/PrivacyAgree";
import {toastFail} from "@/utils/toastUtils";
import LikedQuizManage from "@/components/main/manage/LikedQuizManage";
import InterestedQuizManage from "@/components/main/manage/InterestedQuizManage";
import MyAnswerManage from "@/components/main/manage/MyAnswerManage";
import Withdraw from "@/components/main/Withdraw.vue";
import Home_New from "@/components/main/home/Home_New.vue";
import ContentNew from "@/components/main/content/ContentNew.vue";
import MyPage from "@/components/main/mypage/MyPage.vue";
import MyQuizManageNew from "@/components/main/manage/MyQuizManageNew.vue";
import LikedQuizManageNew from "@/components/main/manage/LikedQuizManageNew.vue";
import InterestedQuizManageNew from "@/components/main/manage/InterestedQuizManageNew.vue";
import MyAnswerManageNew from "@/components/main/manage/MyAnswerManageNew.vue";
import DailyQuizManageNew from "@/components/main/manage/DailyQuizManageNew.vue";
import ContentSearch from "@/components/main/content/ContentSearch.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', alias: '/home', component: Home_New},
        { path: '/quiz/:id', alias: '/quiz/:id', component: ContentNew},
        { path: '/search?q=:keyword', alias: '/search', component: ContentSearch},
        { path: '/mypage', alias: '/mypage', component: MyPage},
        { path: '/term/privacy', alias: '/term/privacy', component: Privacy},
        { path: '/term/service', alias: '/term/service', component: Service},
        { path: '/term/privacy-agree', alias: '/term/privacy-agree', component: PrivacyAgree},
        { path: '/manage', alias: '/manage', component: MyQuizManageNew, meta: { requiresAuth: true}},
        { path: '/daily', alias: '/daily', component: DailyQuizManageNew, meta: { requiresAuth: true}},
        { path: '/liked', alias: '/liked', component: LikedQuizManageNew, meta: { requiresAuth: true}},
        { path: '/interested', alias: '/interested', component: InterestedQuizManageNew, meta: { requiresAuth: true}},
        { path: '/answered', alias: '/answered', component: MyAnswerManageNew, meta: { requiresAuth: true}},
        { path: '/withdraw', alias: '/withdraw', component: Withdraw},
    ],
})

router.beforeEach(async (to, from, next) => {
    const isAccessTokenValid = await store.dispatch('accessTokenValid');

    if (to.meta.requiresAuth) {
        if(!store.getters.isAuthenticated) {
            toastFail("로그인 후 이용 가능합니다.");
            next('/');
        }else{
            $('.main_content_area').animate( { scrollTop : $('.main_content_area').offset().top }, 200 );
            next();
        }
    } else {
        $('.main_content_area').animate( { scrollTop : $('.main_content_area').offset().top }, 200 );
        next();
    }
})

export default router;