<template>
  <div class="withdraw_area">
    <p>탈퇴된 회원입니다.</p>
  </div>
</template>
<script>
import Footer from "@/components/layout/Footer.vue";

export default{
  name: 'Withdraw',
  components: {Footer},
  data : () => ({
    login : false,
    registerModalOn : false,
    writeModalOn: false,
    scrollAnimationDuration: 500
  }),
}
</script>

<style scoped>
@import "@/assets/common/animation.css";
@import "@/assets/main/Withdraw.css";
</style>