<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="modal_write_container">
      <div class="write_modal close_btn_area">
        <button type="button" class="close_btn" @click="closeWriteModal">×</button>
      </div>
      <div class="write_modal_content_wrap">
        <div class="input_area">
          <label id="select_label_category_main">카테고리 <span class="require">*</span></label>
          <ComboBox
              ref="comboBoxRef"
              :items="categoryList"
              :placeholder="'-'"
              @onSelectCategoryId="onSelectCategoryId"
          />

          <label id="input_label_quiz_title" for="quiz_title">퀴즈 제목 <span class="require">*</span></label>
          <input type="text" id="quiz_title" name="quiz_title" placeholder="퀴즈 제목을 입력하세요."/>

          <label id="input_label_quiz_question"  for="quiz_question">퀴즈 내용 <span class="require">*</span></label>
          <textarea id="quiz_question" name="quiz_question" placeholder="퀴즈 내용을 입력하세요."/>

          <label id="input_label_quiz_correct_keyword" for="correct_keyword">힌트 키워드</label>
          <input type="text" id="correct_keyword" name="correct_keyword" placeholder="힌트 키워드를 입력해 주세요."/>
        </div>

        <div class="btn_area">
          <button class="submit_btn" @click="problemRegist">{{ modifyQuizId > 0 ? '수정' : '등록'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr, isNullOrZero} from '@/utils/validationUtils';
import {toastFail, toastSuccess} from "@/utils/toastUtils";
import ComboBox from "@/components/unit/ComboBox";

export default {
  components: {ComboBox},
  props: ['writeModalOn', 'categoryId'],
  name: 'WriteModal',
  data: () => ({
    fullSize: document.querySelector("#body").style.width,
    categorySelectId : 0,
    categoryList : {},
    validate: false,
    writeModalOpenCheck: false,
    modifyQuizId: 0,
    selectCategoryItem: {}
  }),
  computed: {
    writeModalOnCheck() {
      return this.writeModalOn;
    },
  },
  watch: {
    writeModalOnCheck(){
      if(this.writeModalOn && !this.writeModalOpenCheck){
        this.writeModalOpenCheck = true;
        this.getCategories();
      }
    },
    categoryId(){
      this.getCategories();
    }
  },
  methods:{
    getCategories(){
      apiClient.get('/api/public/categories').then(data => {
        const categoryList = data.data.resData;
        const tempList = [];
        if(categoryList != null && categoryList.length > 0){
          for (let i=0; i < categoryList.length; i++){
            tempList.push({value: categoryList[i].categoryId, name: categoryList[i].categoryName, subList: []});
            let subTempList = categoryList[i].subCategoryList;
            for (let j=0; j < subTempList.length; j++){
              tempList[i].subList.push({value: subTempList[j].subCategoryId, name: subTempList[j].subCategoryName});

              if(subTempList[j].subCategoryId == this.categoryId){
                this.selectCategoryItem = {value: subTempList[j].subCategoryId, name: subTempList[j].subCategoryName};
              }
            }
          }
        }
        this.categoryList = tempList;
        this.selectedCateogry();
      }).catch(() => {
        this.categoryList = [];
      });
    },
    selectedCateogry(){
      console.log(this.selectCategoryItem);
      if(this.selectCategoryItem != null || this.selectCategoryItem != ''){
        this.$refs.comboBoxRef.selectItem(this.selectCategoryItem);
      }else{
        this.$refs.comboBoxRef.selectItem('');
      }
    },
    onSelectCategoryId(item) {
      this.categorySelectId = item;
    },
    problemRegist(){
      if(isNullOrZero(this.categorySelectId)){
        toastFail('카테고리를 선택해 주세요.');
        $('#category_main').focus();
        return false;
      }

      if(isNullOrEmptyStr($('#quiz_title').val())){
        toastFail('퀴즈 제목을 입력해주세요.');
        $('#quiz_title').focus();
        return false;
      }

      if(isNullOrEmptyStr($('#quiz_question').val())){
        toastFail('문제를 입력해 주세요.');
        $('#quiz_question').focus();
        return false;
      }

      let params = {};
      params.category = this.categorySelectId;
      params.title = $('#quiz_title').val();
      params.content = $('#quiz_question').val();
      params.referenceImg = $('#img_file_name').text();
      params.correctKeyword = $('#correct_keyword').val();

      if(this.modifyQuizId > 0){
        params.quizId = this.modifyQuizId;

        apiClient.patch('/api/quiz', params).then(data => {
          if(data.data.resCode == '00'){
              toastSuccess('퀴즈 수정 성공하였습니다.');

            $('#quiz_title').val('');
            $('#quiz_question').val('');
            $('#correct_keyword').val('');
            this.closeWriteModal();
          }else{
            toastFail('오류가 발생하였습니다.');
          }
        })
      } else{
        apiClient.post('/api/quiz', params).then(data => {
          if(data.data.resCode == '00'){
              toastSuccess('퀴즈 등록 성공하였습니다.');

            $('#quiz_title').val('');
            $('#quiz_question').val('');
            $('#correct_keyword').val('');
            this.closeWriteModal();
          }else{
            toastFail('오류가 발생하였습니다.');
          }
        })
      }
      
    },
    modify(quizId){
      apiClient.get('/api/my-quiz/modify/' + quizId).then(data => {
        const myQuizModifyInfo = data.data.resData;

        if(myQuizModifyInfo != null){
          $('#quiz_title').val(myQuizModifyInfo.title);
          $('#quiz_question').val(myQuizModifyInfo.content);
          $('#correct_keyword').val(myQuizModifyInfo.hintKeywords);

          const categoryItem = {value: myQuizModifyInfo.categoryId, name: myQuizModifyInfo.subCategoryNm}
          this.$refs.comboBoxRef.selectItem(categoryItem);

          this.modifyQuizId = quizId;
        }
      })
    },
    closeWriteModal(){
      this.$emit('closeWriteModal');
      if(this.modifyQuizId > 0){
        this.modifyQuizId = 0;
        this.$refs.comboBoxRef.selectItem('');
        $('#quiz_title').val('');
        $('#quiz_question').val('');
        $('#correct_keyword').val('');
      }
    }
  }
}
</script>
<style scoped>
  @import "@/assets/modal/write/WriteModal.css";
</style>